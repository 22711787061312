import { Platform } from 'react-native';
import Constants from 'expo-constants';

/* eslint-disable camelcase */
const domain = Constants?.expoConfig?.extra?.baseUrl ?? 'stl.lnpreprod.in';;
const baseUrl = Platform.OS === 'web' && process.env.NODE_ENV && process.env.NODE_ENV === 'production' ? '' : `https://${domain}`;
export const config = {
    domain,
    searchBaseUrl: process.env.NEXT_PUBLIC_SEARCH_BASE_URL ?? `${baseUrl}/api/_search`,
    editorBaseUrl: process.env.NEXT_PUBLIC_EDITOR_BASE_URL ?? 'https://editor.lecturenotes.in/browser/b6cb979/cool.html?WOPISrc=http://172.26.8.51:3291/wopi/files/',
    baseUrl: process.env.NEXT_PUBLIC_BASE_URL ?? `${baseUrl}`,
    contentBaseUrl: process.env.NEXT_PUBLIC_CONTENT_BASE_URL ?? `${baseUrl}/api/_content`,
    academicsBaseUrl: process.env.NEXT_PUBLIC_ACADEMIC_BASE_URL ?? `${baseUrl}/api/_academics`,
    ssoBaseUrl: process.env.NEXT_PUBLIC_SSO_BASE_URL ?? `${baseUrl}/api/_accounts`,
    authTokenName: process.env.NEXT_PUBLIC_AUTH_TK_NAME ?? 'lntkt',
    faqBaseUrl: process.env.NEXT_PUBLIC_FAQ_BASE_URL ?? `${baseUrl}/api/_faqapi`,
    notificationBaseUrl: process.env.NEXT_PUBLIC_NOTIFICATION_BASE_URL ?? `${baseUrl}/api/_notification`,
    // coreClientUi: process.env.NEXT_PUBLIC_CORE_CLIENT_UI ||`https://app.${domain}`,
    // clientUi: process.env.NEXT_PUBLIC_CLIENT_UI ||`${baseUrl}/api/_admin`,
    lnMarketplace: process.env.NEXT_PUBLIC_LN_MARKET_PLACE ?? `${baseUrl}/api/_marketplace`,
    billingBaseUrl: process.env.NEXT_PUBLIC_BILLING_BASE_URL ?? `${baseUrl}/api/_billingapi`,
    classroomBaseUrl: process.env.NEXT_PUBLIC_CLASSROOM_BASE_URL ?? `${baseUrl}/api/_classroom`,
    testPlatformBaseUrl: process.env.NEXT_PUBLIC_TEST_PLATFORM_BASE_URL ?? `${baseUrl}/api/_testplatform`,
    clientBillingBaseUrl: process.env.NEXT_PUBLIC_CLIENT_BILLING_BASE_URL ?? `${baseUrl}/api/_clientbilling`,
    chatBaseUrl: process.env.NEXT_PUBLIC_CHAT_BASE_URL ?? `${baseUrl}/api/_chatdf`,
    chatWsUrl: process.env.NEXT_PUBLIC_CHAT_BASE_URL ?? `${baseUrl.replace('http','ws')}/api/_chatdf/ws/chat`,
    ttssBaseUrl: process.env.NEXT_PUBLIC_TTSS_BASE_URL ?? `${baseUrl}/api/_ttss`,
    cookie: {
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? 'localhost'
    },
    googleWebClientId: process.env.NEXT_PUBLIC_GOOGLE_WEB_CID ?? '129859122012-lv6ildls0lc2cg3omvb3j4e0sfj6hkfr.apps.googleusercontent.com',
    googleAndroidClientId: process.env.NEXT_PUBLIC_GOOGLE_ANDROID_CID ?? '129859122012-qqaqf8t3tfr1m5ulnuoilj2n9m00g5g7.apps.googleusercontent.com',
    //'129859122012-8ak0dnbenntjb3pi5aodlncfmibn3dro.apps.googleusercontent.com',
    googleIOSClientId: '129859122012-0v77k2u8bafo3datqh7fvlp58648a2lj.apps.googleusercontent.com',
    expoClientId: 'GOOGLE_GUID.apps.googleusercontent.com',
    razorpayKey: process.env.NEXT_PUBLIC_RZP_KEY ?? 'rzp_live_5eEhTFzCgs9Oam',
    app_config: {
        app_name: 'Lecturenotes',
        app_company_name: 'LectureNotes Technologies Pvt. Ltd.'
    },
    youtubeDataApiKey: 'AIzaSyCtIDCM-_7CtBSY2KkGaL98dWDAgiLlYnA',
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    blogBaseUrl: `${baseUrl}/blog/`,
    // FIREBASE SETTINGS
    firebaseApiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY ?? 'AIzaSyBaNSQ39fVmt3bTzy9Z4t1fBTgYmEHRNao',
    firebaseAuthDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ?? 'lecturenotes-learner.firebaseapp.com',
    firebaseDatabaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL ?? 'https://lecturenotes-learner.firebaseio.com',
    firebaseProjectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ?? 'lecturenotes-learner',
    firebaseStorageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET ?? 'lecturenotes-learner.appspot.com',
    firebaseMessagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID ?? '129859122012',
    firebaseAppId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID ?? '1:129859122012:web:f50676baf6f8209f0a0799',
    firebaseMeasurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID ?? 'G-M0CS9FLBKB',
    firebaseVapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY ?? 'BPKx-wjY9nmdr5rFchRcwVUftuBZF8uEFxw578hit1vPtrADEC6oUSRtknlYKxR2wxUuuW9L5IWdHmV3n5ZuGWc',
};