import { createSelector } from 'reselect';

export const _getUser = (state: { user: any; }) => ({user: state.user});
export const _getToken = (state: { token: any; }) => ({token: state.token});

export const _getStoreConfig = (state: { storeConfig: any; }) => ({storeConfig: state.storeConfig});
export const _getToasts = (state: { toasts: any; }) => ({toasts: state.toasts});
export const _getLoaded = (state: { loaded: any; }) => ({loaded: state.loaded});
export const _getPermission = (state: { permission: any; }) => ({permission: state.permission});
export const _getPreference = (state: { preference: any; }) => ({preference: state.preference});
export const _getClient = (state: { client: any; }) => ({client: state.client});
export const _getWallet = (state: { wallet: any; }) => ({wallet: state.wallet});
export const _getWelcomePopup = (state: { welcomePopup: any; }) => ({welcomePopup: state.welcomePopup});
export const _getGlobalStore = (state: { globalStore: any; }) => ({globalStore: state.globalStore});
export const _getNotification = (state: { notification: any; }) => ({notification: state.notification});
export const _getClassroom = (state: { classroom: any; }) => ({classroom: state.classroom});
export const _getClientConfig = (state: { clientConfig: any; }) => ({clientConfig: state.clientConfig});

export const getStoreConfig = createSelector(_getStoreConfig, (storeConfig) => storeConfig);
export const getToasts = createSelector(_getToasts, (toasts) => toasts);
export const getLoaded = createSelector(_getLoaded, (loaded) => loaded);
export const getPermission = createSelector(_getPermission, (permission) => permission);
export const getPreference = createSelector(_getPreference, (preference) => preference);
export const getClient = createSelector(_getClient, (client) => client);
export const getWallet = createSelector(_getWallet, (wallet) => wallet);
export const getWelcomePopup = createSelector(_getWelcomePopup, (welcomePopup) => welcomePopup);
export const getGlobalStore = createSelector(_getGlobalStore, (globalStore) => globalStore);
export const getNotification = createSelector(_getNotification, (notification) => notification);
export const getClassroom = createSelector(_getClassroom, (classroom) => classroom);
export const getClientConfig = createSelector(_getClientConfig, (clientConfig) => clientConfig);
export const getUser = createSelector(_getUser, (user) => user);
export const getToken = createSelector(_getToken, (token) => token);